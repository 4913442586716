import styled from 'styled-components';

export const ServiceCard = styled.div`
  display: flex;
  flex-direction: column;
  height: 300px;
  width: 100%;
  max-width: 300px;
  margin: 0 0 13px 0;

  @media screen and (min-width: 600px) {
    height: 250px;
    width: 49%;
    margin: 0 0 20px 0;
  }

  @media screen and (min-width: 900px) {
    height: 270px;
    max-width: 270px;
    margin: 0 0 28px 0;
  }

  @media screen and (min-width: 1200px) {
    margin: 0 0 35px 0;
  }
`;

export const CardImage = styled.img`
  height: 60%;
  width: 100%;
  object-fit: cover;
`;

export const CardInfoContent = styled.div`
  padding: 10px 10px;
  height: 40%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000023;
`;

export const CardText = styled.span`
  color: whitesmoke;
  text-align: center;
  font-size: 16px;
  font-weight: 600;

  @media screen and (min-width: 900px) {
    font-size: 18px;
  }

  @media screen and (min-width: 1200px) {
    font-size: 20px;
  }
`;
