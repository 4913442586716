import { useLang } from './hooks';

import * as S from './SupplyGroup.styled';

const SupplyGroup = () => {
  const { textInfo } = useLang();

  return (
    <S.SupplyGroup>
      <S.SupplyGroup__InnerGroup>
        <S.Description>{textInfo.text}</S.Description>
      </S.SupplyGroup__InnerGroup>
    </S.SupplyGroup>
  );
};

export default SupplyGroup;
