import { LangChangeButton } from 'infrastructure/components';

import * as S from './Banner.styled';
import { useLang } from './hooks';

const Banner = () => {
  const { textInfo } = useLang();

  return (
    <S.Banner>
      <S.Banner__InnerCont>
        <LangChangeButton />

        <S.PresentationText>{textInfo}</S.PresentationText>
      </S.Banner__InnerCont>
    </S.Banner>
  );
};

export default Banner;
