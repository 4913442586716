import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

:root {
  /*
* =============== 
* Variables
* ===============
*/

/*
* Page Main color 
*/
  --color-white: #fff;
  --color-soft-white: #f2f2f2;
  
/*
* Trinkes 
*/
  --transition-fast: all 0.2s ease-in-out;
  --transition: all 0.3s ease-in-out;
  --transition-slow: all 0.6s ease-in-out;
  --transition-very-slow: all 0.9s ease-in-out;
  --spacing: 0.1rem;
  --subtitle-spacing: 3px;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

/*
* =============== 
* Global Styles
* ===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


html,
body {
  height: 100%;
  overflow: hidden;
  position: relative;
}

:root {
  font-family: 'Inter', 'Helvetica Neue', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell,
    'Open Sans',  sans-serif;
}

@media screen and (min-width: 280px) {
  :root {
    font-size: calc(14px + 8 * ((100vw - 280px) / 1640));
  }
  p {
    line-height: calc(19.6px + 11.2 * ((100vw - 280px) / 1640));
  }
}

@media screen and (min-width: 1920px) {
  :root {
    font-size: 20px;
  }

  p {
    line-height: 28px;
  }
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  line-height: 1.25;
}

h1 {
  font-size: 2.79rem;
}

h2 {
  font-size: 1.8rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 0.875rem;
}

p {
  margin-bottom: 1.25rem;
  color: var(--clr-grey-5);
}


/*
* ==================== 
* Custom Project Style
* ====================
*/

#root {
  min-height: 100%;
}
`;

/*
 * ====================
 * Custom Project GLobal Components
 * ====================
 */

export const Section = styled.section`
  width: 100%;
  min-height: 600px;
  height: calc(100vh - 54px);

  @media screen and (min-width: 600px) {
    height: calc(100vh - 80px);
  }

  @media screen and (min-width: 900px) {
    height: calc(100vh - 103px);
  }
`;

export const SectionTitle = styled.h2`
  color: #000023;
  font-size: 24px;

  @media screen and (min-width: 600px) {
    font-size: 28px;
  }

  @media screen and (min-width: 900px) {
    font-size: 36px;
  }

  @media screen and (min-width: 1200px) {
    font-size: 40px;
  }
`;

export default GlobalStyle;
