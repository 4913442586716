import { Section } from 'globalStyle';
import styled from 'styled-components';

export const SupplyGroup = styled(Section)`
  min-height: auto;
  height: auto;
  margin: 90px 0 90px 0;
`;

export const SupplyGroup__InnerGroup = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding: 0px 30px 0 30px;
`;

export const Title = styled.h2`
  font-size: 13px;
  margin-bottom: 20px;

  @media screen and (min-width: 600px) {
    font-size: 18px;
  }

  @media screen and (min-width: 900px) {
    display: none;
  }
`;

export const Description = styled.p`
  font-size: 13px;
  width: 250px;
  text-align: center;

  @media screen and (min-width: 600px) {
    font-size: 18px;
    width: 450px;
  }

  @media screen and (min-width: 900px) {
    font-size: 24px;
    width: 900px;
  }
`;
