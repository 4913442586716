import { useId } from 'react';
import * as S from './FooterColumn.styled';

interface Props {
  footerColumnCustomProps?: FooterColumnProps;
  footerColumnProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
}

interface FooterColumnProps {
  title: string;
  tagList: string[];
}

const FooterColumn = ({ footerColumnCustomProps, ...footerColumnProps }: Props) => {
  const tagItemId = useId();

  const footerColumnContent: FooterColumnProps = footerColumnCustomProps || {
    title: 'Lorem Ipsum',
    tagList: ['Lorem Ipsum', 'Lorem Ipsum', 'Lorem Ipsum'],
  };

  return (
    <S.FooterColumn {...footerColumnProps}>
      <S.Title>{footerColumnContent.title}</S.Title>
      <S.TagList>
        {footerColumnContent.tagList.map((tagItem, i) => (
          <S.TagItem key={`${tagItemId}-${i}`}>{tagItem}</S.TagItem>
        ))}
      </S.TagList>
    </S.FooterColumn>
  );
};

export default FooterColumn;
