export const ProductListES: string[] = [
  'Suministros De Andamios Tipo Cup-Lock.',
  'Equipos de Izado y Descarga.',
  'Generadores Eléctricos.',
  'Compresores de Aire.',
  'Máquinas de Soldar.',
  'Hidrojet Industriales.',
  'Manlift.',
  'Luminarias.',
  'Gandolas.',
  'Lowboy.',
  'Volquetas.',
  'Retroexcavadora.',
  'Payloader.',
];

export const ProductListENG: string[] = [
  'Cup-Lock Scaffolding Supplies.',
  'Lifting and Unloading Equipment.',
  'Electric Generators.',
  'Air Compressors.',
  'Welding Machines.',
  'Industrial Hydrojet.',
  'Manlift.',
  'Luminaires.',
  'Containers.',
  'Lowboy.',
  'Dumpers.',
  'Backhoe.',
  'Payloader.',
];
