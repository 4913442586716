import { GoogleMap, ContactForm } from 'infrastructure/components';

import * as S from './Contact.styled';

const Contact = () => {
  return (
    <S.Contact id='contacto'>
      <S.Contact__InnerCont>
        <S.MapCont>
          <GoogleMap />
        </S.MapCont>
        <S.FormCont>
          <ContactForm />
        </S.FormCont>
      </S.Contact__InnerCont>
    </S.Contact>
  );
};

export default Contact;
