import { useState } from 'react';
import { MenuButton } from 'infrastructure/components';

import { useShowSpringAnim } from './hooks';
import { Props } from './interfaces';
import * as S from './MobileNavbar.styled';

const MobileNavbar = ({ links, ...restProps }: Props) => {
  const [show, setShow] = useState<boolean>(false);
  const { fadeOutSpring, moveNavSpring, unMountSpring } = useShowSpringAnim({ show });

  return (
    <S.MobileNavbar {...restProps}>
      <MenuButton setShow={() => setShow(!show)} show={show} />

      <S.MobileNavLinkCont style={{ ...moveNavSpring }}>
        {links.map(({ to, name }) => (
          <S.LinkTo to={to} key={name}>
            {name}
          </S.LinkTo>
        ))}
      </S.MobileNavLinkCont>

      <S.MobileNavbarFocus
        tabIndex={-1}
        onClick={() => setShow(false)}
        style={{ ...unMountSpring, ...fadeOutSpring }}
      />
    </S.MobileNavbar>
  );
};

export default MobileNavbar;
