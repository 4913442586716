import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

interface ContactProps {
  fullname: string;
  email: string;
  message: string;
}

interface ContactResponseProps {
  status: 'idle' | 'loading' | 'success' | 'error';
  message: string;
}

export const homeApi = createApi({
  reducerPath: 'homeApi',
  baseQuery: fetchBaseQuery({
    //give the base url from .env
    baseUrl: process.env.REACT_APP_ROOT_ENDPOINT,
  }),

  endpoints: (builder) => ({
    sendContact: builder.mutation<ContactResponseProps, ContactProps>({
      query: ({ fullname, email, message }) => ({
        url: '',
        method: 'POST',
        body: { fullname, email, message },
      }),
    }),
  }),
});

export const { useSendContactMutation } = homeApi;
