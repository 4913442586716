import { useDeferredValue, useEffect, useState } from 'react';
import { useAppSelector } from 'infrastructure/hooks';
import { useSendContactMutation } from 'infrastructure/store/apis/home';
import { FormValuesProps, FormErrorsProps } from '../interfaces/interfaces';
import useValidation from '../utils/useValidation';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const useForm = (initValues: FormValuesProps, error: FormErrorsProps) => {
  const { validation } = useValidation();

  const [sendContact, { isLoading }] = useSendContactMutation();
  const { lang } = useAppSelector((state) => state.home);
  const MySwal = withReactContent(Swal);

  //inputs state
  const [values, setValues] = useState({
    ...initValues,
  });

  const deferredValue = useDeferredValue(values);

  //inputs errors
  const [errors, setErrors] = useState({
    ...error,
  });

  //validation reset
  const [isValidating, setIsValidating] = useState(false);

  //validation reset
  const [disableSubmit, setDisableSubmit] = useState(false);

  //submit handler
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await sendContact({
        fullname: values.name,
        email: values.email,
        message: values.message,
      })
        .unwrap()
        .then(() => {
          MySwal.fire({
            toast: true,
            icon: 'success',
            title:
              lang === 'es'
                ? 'Tu mensaje ha sido enviado exitosamente'
                : 'Your message has been sent successfully',
            position: 'top-end',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
        });
    } catch (error) {
      const statusError = error as unknown as {
        status: number;
        data: { status: string; message: string };
      };

      let errorMessage;
      if (statusError.data.message === 'Invalid email.') {
        errorMessage =
          lang === 'es' ? 'Por favor introduce un correo valido' : 'Please enter a valid email';
      } else {
        errorMessage =
          lang === 'es'
            ? 'Opps, ha ocurrido un error, comprueba tu conexion a internet e intente mas tarde'
            : 'Opps, an error has occurred, check your internet connection and try again later';
      }

      MySwal.fire({
        toast: true,
        icon: 'error',
        title: errorMessage,
        position: 'top-end',
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
      });
    } finally {
      setIsValidating(false);
      setValues({ ...initValues });
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    if (!isValidating) {
      setIsValidating(true);
    }

    setValues((prevFormData) => {
      return { ...prevFormData, [name]: type === 'checkbox' ? checked : value };
    });
  };

  useEffect(() => {
    setErrors(isValidating ? validation(values) : { ...error });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deferredValue]);

  useEffect(() => {
    setDisableSubmit(isValidating ? Object.values(errors).some((error) => error !== '') : true);
  }, [errors, isValidating]);

  return {
    values,
    errors,
    disableSubmit,
    isLoading,
    isValidating,
    handleSubmit,
    handleChange,
  };
};

export default useForm;
