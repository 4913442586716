import styled from 'styled-components';

export const MainButton = styled.button`
  outline: none;
  border: none;
  font-size: 14px;
  padding: 10px 15px;
  text-transform: uppercase;
  font-family: inherit;
  font-weight: 500;
  cursor: pointer;
  color: var(--color-soft-white);
  background-color: #970005;

  @media screen and (min-width: 600px) {
    font-size: 20px;
    padding: 10px 20px;
  }
`;
