import styled from 'styled-components';

export const FormTextArea = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;

  & div:focus {
    border: 1px solid #000023;
  }

  & div[disabled] {
    background-color: var(--color-grey);
    color: #625e5e;
    cursor: not-allowed;
  }

  & div[data-focused='true'] ~ span {
    display: block;
  }

  & div[data-focused='true'] {
    border: 1px solid #ef4848;
  }
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: #696767;
  font-weight: 500;
`;

export const TextAreaCont = styled.div`
  border-radius: 15px;
  margin: 5px 0px 60px 0px;
  padding: 15px;
  transition: var(--transition);
  border: 1px solid #69676770;
  background-color: white;

  @media screen and (min-width: 600px) {
    margin: 5px 0px 30px 0px;
  }
`;

export const TextArea = styled.textarea`
  height: 160px;
  font-size: 16px;
  width: 100%;
  border: none;
  outline: none;
  resize: none;
  background-color: transparent;
  font-family: inherit;
  color: #000023;

  &::placeholder {
    color: #696767;
    font-size: 18px;
    font-family: inherit;
    font-weight: 500;
    text-transform: capitalize;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    padding: 10px 0px;
  }

  &::-webkit-scrollbar-track {
    background-color: #80808050;
    height: 5px;
    padding: 10px 0px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-grey);
    border-radius: 5px;
    height: 5px;
    padding: 10px 0px;
  }
`;

export const Error = styled.span`
  text-align: left;
  font-size: 16px;
  color: #ef4848;
  display: none;
  position: absolute;
  bottom: 5px;
`;
