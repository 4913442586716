import { Section } from 'globalStyle';
import styled from 'styled-components';

export const Contact = styled(Section)`
  height: auto;
  min-height: auto;
  background-color: #f1f3f4;
`;

export const Contact__InnerCont = styled.div`
  max-width: 1140px;
  margin: 0 auto;
  /* height: 565px; */
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 900px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: calc(100vh - 103px);
    max-height: 750px;
  }
`;

export const MapCont = styled.div`
  width: 100%;
  height: 280px;

  @media screen and (min-width: 900px) {
    height: 100%;
    width: 50%;
    margin-bottom: 0px;
  }
`;

export const FormCont = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;

  @media screen and (min-width: 900px) {
    width: 50%;
  }
`;
