import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Props } from './interfaces';
import { animated } from '@react-spring/web';

export const MobileNavbar = styled.div.attrs((props: Props) => props)`
  display: flex;

  @media screen and (min-width: 900px) {
    display: none;
  }
`;

export const MobileNavbarFocus = styled(animated.div)`
  position: absolute;
  display: none;
  top: 0px;
  left: 0%;
  width: 100vw;
  height: 100vh;
  background-color: hsl(0, 0%, 0%, 10%);
  backdrop-filter: blur(5px);
`;

export const MobileNavLinkCont = styled(animated.nav)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-start;
  padding: 150px 25px 60px 60px;
  background-color: hsl(131, 68%, 235%, 80%);
  position: absolute;
  top: 0px;
  height: 100vh;
  width: min(80%, 300px);
  z-index: 1;

  @media screen and (min-width: 600px) {
    padding: 200px 50px 60px 60px;
  }

  @media screen and (max-height: 600px) {
    padding: 120px 50px 10px 10px;
  }
`;

export const LinkTo = styled(Link)`
  color: var(--color-blue);
  margin-right: 25px;
  margin-bottom: 50px;
  font-size: 24px;
`;
