import styled from 'styled-components';
import { Section, SectionTitle } from 'globalStyle';
import { productsBg } from 'infrastructure/assets';

export const Equipment = styled(Section)`
  height: auto;
  min-height: auto;
  background-image: linear-gradient(
      0deg,
      rgba(255, 255, 255, 85%) 0%,
      rgba(255, 255, 255, 85%) 100%
    ),
    url(${productsBg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const Equipment__InnerCont = styled.div`
  max-width: 1140px;
  margin: 0 auto;
  padding: 56px 46px;
  height: 100%;
  width: 100%;
`;

export const Title = styled(SectionTitle)`
  margin: 0 10px 32px 10px;
`;

export const Description = styled.p`
  display: none;
  margin: 0 10px 89px 10px;
  font-size: 14px;

  @media screen and (min-width: 600px) {
    font-size: 14px;
    display: block;
  }

  @media screen and (min-width: 900px) {
    font-size: 16px;
  }

  @media screen and (min-width: 1200px) {
    font-size: 18px;
  }
`;

export const EquipmentList = styled.ul`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;
`;

export const AndMore = styled.li`
  color: #6d6d6d;
  font-size: 16px;
  font-weight: 400;
  width: 100px;

  @media screen and (min-width: 360px) {
    width: 85px;
  }

  @media screen and (min-width: 450px) {
    width: 130px;
  }

  @media screen and (min-width: 600px) {
    font-size: 14px;
    width: 200px;
  }

  @media screen and (min-width: 900px) {
    font-size: 16px;
    width: 300px;
  }

  @media screen and (min-width: 1200px) {
    font-size: 18px;
    width: 300px;
  }
`;
