import { useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { Footer } from 'infrastructure/components';
import { useScrollTopOrHash } from 'infrastructure/hooks';

import * as S from './LayoutMain.styled';

const LayoutMain = () => {
  const scrollCont = useRef(null);
  useScrollTopOrHash({ scrollCont });

  return (
    <S.LayoutMain data-component-name='Layout main' ref={scrollCont}>
      <Outlet />
      <Footer />
    </S.LayoutMain>
  );
};

export default LayoutMain;
