import styled from 'styled-components';

export const FormInput = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;

  &[data-ischeckbox='true'] {
    flex-direction: row-reverse;
    justify-content: flex-end;

    & label {
      font-size: 14px;
      cursor: pointer;
      font-weight: 500;
    }
  }
`;

export const Input = styled.input`
  padding: 15px;
  margin: 0px 0px 25px 0px;
  border-radius: 15px;
  transition: var(--transition);
  outline: none;
  border: 1px solid #69676770;
  font-size: 16px;
  background-color: white;
  color: #000023;

  &:focus {
    border: 1px solid #000023;
  }

  &::placeholder {
    color: #696767;
    font-size: 18px;
    font-family: inherit;
    font-weight: 500;
    text-transform: capitalize;
  }

  &[disabled] {
    background-color: var(--color-grey);
    color: #625e5e;
    cursor: not-allowed;
  }

  &[data-focused='true'] ~ span {
    display: block;
  }

  &[data-focused='true'] {
    border: 1px solid #ef4848;
  }

  &[type='checkbox'] {
    margin-right: 10px;
    cursor: pointer;
  }
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: var(--color-soft-white);
`;

export const Span = styled.span`
  position: absolute;
  font-size: 16px;
  padding: 3px;
  color: #ef4848;
  display: none;
  bottom: -2px;
`;
