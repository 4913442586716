import * as S from './GoogleMap.styled';

const GoogleMap = () => {
  return (
    <S.GoogleMap
      title='corporación xeus Google Map'
      src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d982.1640910853063!2d-65.0325451!3d10.045189!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c2d1f353be8b455%3A0x6a83189674e3b9ec!2sESTACION%20DE%20SERVICIOS%20TEXACO!5e0!3m2!1sen!2sve!4v1700056124942!5m2!1sen!2sve'
      allowFullScreen={false}
      loading='lazy'
      referrerPolicy='no-referrer'
    />
  );
};

export default GoogleMap;
