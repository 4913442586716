import { useTextArea } from './hooks';
import { CSSProperties } from 'react';

import * as S from './FormTextArea.styled';

interface Props {
  FormTextAreaProps: FormTextAreaProps;
  style?: CSSProperties;
  className?: string;
}

interface FormTextAreaProps {
  id: string;
  name: string;
  placeholder?: string;
  required?: boolean;
  label?: string | [JSX.Element, JSX.Element];
  value: string | number;
  errorMessage: string;
  isLabelSmall?: boolean;
  isInFAQ?: boolean;
  isValidating?: boolean;
  onChange?: (e: React.ChangeEvent<any>) => void;
}

const FormTextArea = ({ FormTextAreaProps, style, className }: Props) => {
  const { label, errorMessage, inputProps, focused, handleFocusedBlur, handleFocusedFocus } =
    useTextArea(FormTextAreaProps);

  return (
    <S.FormTextArea className={className} style={style}>
      <S.Label htmlFor={inputProps.id}>{label}</S.Label>
      <S.TextAreaCont data-focused={errorMessage && focused} tabIndex={-1}>
        <S.TextArea {...inputProps} onBlur={handleFocusedBlur} onFocus={handleFocusedFocus} />
      </S.TextAreaCont>

      <S.Error>{errorMessage}</S.Error>
    </S.FormTextArea>
  );
};

export default FormTextArea;
