import { useAppSelector, useAppDispatch } from 'infrastructure/hooks';
import { changeLanguage } from 'infrastructure/store/slices/home';
import * as S from './LangChangeButton.styled';

const LangChangeButton = () => {
  const { lang } = useAppSelector((state) => state.home);
  const dispatch = useAppDispatch();

  return (
    <S.LangChangeButton onClick={() => dispatch(changeLanguage())}>
      <S.ES lang={lang}> ES </S.ES> <S.ENG lang={lang}>ENG</S.ENG>
    </S.LangChangeButton>
  );
};

export default LangChangeButton;
