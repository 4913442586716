import * as S from './MenuButton.styled';

interface MenuButtonProps {
  setShow: () => void;
  show: boolean;
}

const MenuButton = ({ setShow, show }: MenuButtonProps) => {
  return (
    <S.MenuButton type='button' title='Menu Button' onClick={setShow}>
      {show ? <S.Close /> : <S.MenuBtn />}
    </S.MenuButton>
  );
};

export default MenuButton;
