import styled from 'styled-components';
import { bannerImg } from 'infrastructure/assets';
import { Section } from 'globalStyle';

export const Banner = styled(Section)`
  height: calc(100vh - 14px);
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 1) 100%),
    url(${bannerImg});
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: cover;

  @media screen and (min-width: 600px) {
    background-position: center bottom;
  }

  @media screen and (min-width: 900px) {
    background-position: center top;
  }
`;

export const Banner__InnerCont = styled.div`
  position: relative;
  height: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding: 30px 30px 0 30px;

  @media screen and (min-width: 600px) {
    padding: 0px 70px 0 70px;
  }

  @media screen and (min-width: 1200px) {
    padding: 0px 90px 0 90px;
  }
`;

export const PresentationText = styled.h1`
  color: #f6f6f6;
  text-shadow: 5px 5px 5px #000000;
  font-size: 42px;
  position: absolute;
  top: 38%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;

  @media screen and (min-width: 600px) {
    font-size: 48px;
    width: max-content;
  }

  @media screen and (min-width: 900px) {
    font-size: 60px;
  }

  @media screen and (min-width: 1800px) {
    font-size: 72px;
  }
`;
