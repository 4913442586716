import { useAppSelector, useWindowSize } from 'infrastructure/hooks';
import { useEffect, useState } from 'react';

const useLang = () => {
  const { width } = useWindowSize();
  const { lang } = useAppSelector((state) => state.home);
  const [textInfo, setTextInfo] = useState<{
    title: any;
    text: any;
  }>({
    title: '',
    text: '',
  });

  useEffect(() => {
    if (lang === 'es' && width < 900) {
      setTextInfo({
        title: ['Quienes', <br key='br' />, 'somos'],
        text: 'Corporación Xeus nace pensando en suplir la necesidades de	procura, servicios en	el sector	petrolero, petroquímico, carbonífero e industrial.',
      });
    } else if (lang === 'es' && width > 900) {
      setTextInfo({
        title: ['Quienes', <br key='br' />, 'somos'],
        text: 'Corporación Xeus nace pensando en suplir la necesidades de	procura, servicios en	el sector	petrolero, petroquímico, carbonífero e industrial.	Con el fin de llevar a cabo los	requerimientos de nuestros clientes, ejecutando	 nuestras labores en estrecha	concordancias	con	las	normativas técnicas	y legales, así como otros requisitos exigidos por el cliente en	materia	de Calidad, Seguridad, Higiene y Ambiente.',
      });
    } else if (lang !== 'es' && width < 900) {
      setTextInfo({
        title: ['Who are', <br key='br' />, 'we'],
        text: 'Xeus Corporation was created to meet the needs of procurement and services in the oil, petrochemical, coal and industrial sectors.',
      });
    } else if (lang !== 'es' && width > 900) {
      setTextInfo({
        title: ['Who are', <br key='br' />, 'we'],
        text: 'Xeus Corporation was created to meet the needs of procurement and services in the oil, petrochemical, coal and industrial sectors. In order to carry out the requirements of our customers, executing our work in close accordance with the technical and legal regulations, as well as other requirements demanded by the customer in terms of Quality, Safety, Hygiene and Environment.',
      });
    } else {
      return;
    }
  }, [lang, width]);

  return { textInfo, width };
};

export default useLang;
