import styled from 'styled-components';

export const LayoutRoot = styled.div`
  display: grid;
  grid:
    [row1-start] 'header' 80px [row1-end]
    [row2-start] 'page' calc(100vh - 80px) [row2-end]
    / auto;
  min-height: 100%;
  margin: 0 auto;

  @media screen and (min-width: 900px) {
    grid:
      [row1-start] 'header' 103px [row1-end]
      [row2-start] 'page' calc(100vh - 103px) [row2-end]
      / auto;
  }
`;
