import { VscChromeClose } from 'react-icons/vsc';
import { HiMenuAlt1 } from 'react-icons/hi';
import styled from 'styled-components';

export const MenuBtn = styled(HiMenuAlt1)`
  font-size: 40px;
  color: var(--color-aquamarine);
  opacity: 0;
  animation: fadeIn ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 0.5s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const Close = styled(VscChromeClose)`
  font-size: 40px;
  color: var(--color-aquamarine);
  opacity: 0;
  animation: fadeIn ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 0.5s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const MenuButton = styled.button`
  width: fit-content;
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 2;
`;
