import styled from 'styled-components';

export const Form = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #f6f6f6;
  width: 100%;
  z-index: 1;

  & button {
    margin-top: 40px;
  }

  & button:disabled {
    cursor: not-allowed;
    display: flex;
    color: grey;
    background-color: #bb4044;
  }

  @media screen and (min-width: 600px) {
    padding: 20px 100px;
  }

  @media screen and (min-width: 600px) {
    padding: 20px;
  }
`;

export const Error = styled.span`
  position: absolute;
  bottom: 100px;
  color: red;
`;

export const SpinnerCont = styled.div`
  margin: 0 auto;
`;
