import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const PageNavLink = styled(NavLink)`
  margin-left: 11px;
  cursor: pointer;
  font-size: 11px;
  transition: var(--transition);
  color: #000023;
  font-weight: 500;

  &:hover {
    color: #6b7488;
  }

  @media screen and (min-width: 600px) {
    margin-left: 12px;
    font-size: 14px;
  }

  @media screen and (min-width: 900px) {
    font-size: 18px;
    margin-left: 32px;
  }

  @media screen and (min-width: 1200px) {
    font-size: 22px;
    margin-left: 32px;
  }

  &[data-hideinmobile='true'] {
    @media screen and (max-width: 900px) {
      display: none;
    }
  }
`;

export const LogoImg = styled.img`
  max-height: 100%;
  width: auto;
`;
