import styled from 'styled-components';

export const LangChangeButton = styled.button`
  display: flex;
  position: fixed;
  right: 0px;
  top: 140px;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 40px;
  border: none;
  cursor: pointer;
  z-index: 50;

  @media screen and (min-width: 600px) {
    right: 0px;
  }

  @media screen and (min-width: 900px) {
    right: 20px;
    top: 180px;

    width: 102px;
    height: 51px;
  }

  @media screen and (min-width: 1200px) {
    right: 50px;
    top: 180px;
  }
`;

interface SelectedLang {
  lang: 'es' | 'eng';
}

export const ES = styled.div<SelectedLang>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  height: 100%;
  width: 50%;
  background-color: ${({ lang }) => (lang === 'es' ? '#000023' : 'whitesmoke')};
  color: ${({ lang }) => (lang === 'es' ? 'whitesmoke' : '#000023')};
  transition: var(--transition);

  @media screen and (min-width: 600px) {
    font-size: 14px;
  }

  @media screen and (min-width: 900px) {
    font-size: 16px;
  }
`;

export const ENG = styled.div<SelectedLang>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  height: 100%;
  width: 50%;
  background-color: ${({ lang }) => (lang === 'eng' ? '#000023' : 'whitesmoke')};
  color: ${({ lang }) => (lang === 'eng' ? 'whitesmoke' : '#000023')};
  transition: var(--transition);

  @media screen and (min-width: 600px) {
    font-size: 14px;
  }

  @media screen and (min-width: 900px) {
    font-size: 16px;
  }
`;
