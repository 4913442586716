import { LazyExoticComponent, Suspense, useId } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { MoonLoader } from 'react-spinners';

import rootRoutes from './routes';

const AppRoutes = () => {
  const id = useId();
  //this components have all the routes of the layouts, those layout can be a components
  //or components with their own routes (like LayoutMain)

  // Scroll to top when route changes
  type ReactComponent = () => JSX.Element;

  interface RoutesProps {
    path: string;
    Component: LazyExoticComponent<ReactComponent> | ReactComponent;
    nestedRoutes?: RoutesProps[];
  }

  const routeCreator = (routesList: RoutesProps[]) => {
    return (
      <>
        {routesList.map(({ path, Component, nestedRoutes }) => {
          return (
            <Route path={path} element={<Component />} key={`${id}-${path}`}>
              {nestedRoutes && routeCreator(nestedRoutes)}
            </Route>
          );
        })}
      </>
    );
  };

  return (
    <Suspense
      fallback={
        <div
          style={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'var(--color-blue-deep)',
          }}
        >
          <MoonLoader color='#1F3487' size={50} speedMultiplier={0.5} />
        </div>
      }
    >
      <Routes>
        {routeCreator(rootRoutes)}
        <Route path='/*' element={<Navigate to='/' />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
