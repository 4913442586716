import { config, useChain, useSpring, useSpringRef } from '@react-spring/web';

interface Props {
  show: boolean;
}

const useShowSpringAnim = ({ show }: Props) => {
  const fadeOutSpringRef = useSpringRef();
  const fadeOutSpring = useSpring({
    ref: fadeOutSpringRef,
    opacity: show ? 1 : 0,
    config: {
      duration: 200,
    },
  });

  const unMountSpringRef = useSpringRef();
  const unMountSpring = useSpring({
    ref: unMountSpringRef,
    display: show ? 'block' : 'none',
    config: config.slow,
  });

  const moveNavSpringRef = useSpringRef();
  const moveNavSpring = useSpring({
    ref: moveNavSpringRef,
    right: show ? '0%' : '-100%',
    config: {
      duration: 400,
    },
  });

  useChain(
    show
      ? [unMountSpringRef, fadeOutSpringRef, moveNavSpringRef]
      : [moveNavSpringRef, fadeOutSpringRef, unMountSpringRef],
    show ? [0, 0.5, 0.5] : [0, 0, 0.6]
  );

  return {
    fadeOutSpring,
    unMountSpring,
    moveNavSpring,
  };
};

export default useShowSpringAnim;
