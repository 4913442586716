import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Header = styled.header`
  grid-area: header;
  background-color: #e5eff0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  padding: 8px 20px;

  @media screen and (min-width: 900px) {
    padding: 8px 40px;
  }

  @media screen and (min-width: 1200px) {
    padding: 8px 80px;
  }
`;

export const HeaderInnerCont = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 1920px;

  @media screen and (min-width: 600px) {
    flex-direction: row;
  }
`;

export const LinkTo = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 100%;
`;
