import { useAppSelector, useWindowSize } from 'infrastructure/hooks';
import { useEffect, useState } from 'react';

const useLang = () => {
  const { width } = useWindowSize();
  const { lang } = useAppSelector((state) => state.home);
  const [textInfo, setTextInfo] = useState<{
    title: string;
    description: String;
  }>({
    title: '',
    description: '',
  });

  useEffect(() => {
    if (lang === 'es' && width < 600) {
      setTextInfo({
        title: 'Visión',
        description:
          'Ser una organización líder a nivel nacional en las distintas áreas de enfoque que nos caracterizan, manteniendo ventaja competitiva en el suministro de bienes, servicios y desarrollando ideas de innovación y calidad.',
      });
    } else if (lang === 'es' && width > 600) {
      setTextInfo({
        title: 'Visión',
        description:
          'Ser una organización líder a nivel nacional en las distintas áreas de enfoque que nos caracterizan, manteniendo ventaja competitiva en el suministro de bienes, servicios y desarrollando ideas de innovación y calidad que respondan a la demanda de la industria y garanticen el crecimiento sostenido de nuestros clientes y el crecimiento profesional de nuestro personal.',
      });
    } else if (lang === 'eng' && width < 600) {
      setTextInfo({
        title: 'Vision',
        description:
          'To be a leading organization at a national level in the different areas of focus that characterize us, maintaining a competitive advantage in the supply of goods and services and developing ideas of innovation and quality.',
      });
    } else if (lang === 'eng' && width > 600) {
      setTextInfo({
        title: 'Vision',
        description:
          'To be a leading organization at a national level in the different areas of focus that characterize us, maintaining a competitive advantage in the supply of goods and services and developing ideas of innovation and quality that respond to the demands of the industry and guarantee the sustained growth of our clients and the professional growth of our personnel.',
      });
    } else {
      return;
    }
  }, [lang, width]);

  return { textInfo };
};

export default useLang;
