import * as S from './ProductItem.styled';

interface Props {
  productItemCustomProps?: ProductItemCustomProps;
  productItemProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLLIElement>, HTMLLIElement>;
}

interface ProductItemCustomProps {
  number: number;
  label: string;
}

const ProductItem = ({ productItemCustomProps, ...productItemProps }: Props) => {
  const ProductItemContent: ProductItemCustomProps = productItemCustomProps || {
    number: 1,
    label: 'Tubería de Perforación.',
  };

  return (
    <S.ProductItem {...productItemProps}>
      <S.ItemNumber>
        {ProductItemContent.number < 10
          ? '0' + ProductItemContent.number.toString()
          : ProductItemContent.number.toString()}
      </S.ItemNumber>
      <S.ItemName>{ProductItemContent.label}</S.ItemName>
    </S.ProductItem>
  );
};

export default ProductItem;
