import { useAppSelector } from 'infrastructure/hooks';
import { FooterLogo } from 'infrastructure/components';
import * as S from './SocialLinks.styled';

const SocialLink = () => {
  const { lang } = useAppSelector((state) => state.home);

  return (
    <S.SocialLinks>
      <FooterLogo />
      <S.title>{lang === 'es' ? 'Contáctanos' : 'Contact us'}</S.title>
      {/* <S.LinksCont>
        <S.FacebookCont>
          <S.FacebookInnerCont>
            <S.Facebook />
          </S.FacebookInnerCont>
        </S.FacebookCont>

        <S.IconCont>
          <S.Twitter />
        </S.IconCont>

        <S.IconCont>
          <S.Linkedin />
        </S.IconCont>
      </S.LinksCont> */}
      <S.EmailCont href='mailto:ventas@corpoxeus.com' target='_blank' rel='noreferrer'>
        <S.EmailIcon />
        <S.Email>ventas@corpoxeus.com</S.Email>
      </S.EmailCont>
      <S.PhoneNumberCont href='tel:02815811832' target='_blank' rel='noreferrer'>
        <S.PhoneIcon />
        <S.Phone>0281-5811832</S.Phone>
      </S.PhoneNumberCont>
      <S.PhoneNumberCont href='tel:02814413402' target='_blank' rel='noreferrer'>
        <S.PhoneIcon />
        <S.Phone>0281-4413402</S.Phone>
      </S.PhoneNumberCont>

      <S.IkusaRight>
        <S.RightLogo>&#169;</S.RightLogo> IKUSA MEDIA C.A. todos los derechos reservados
      </S.IkusaRight>
    </S.SocialLinks>
  );
};

export default SocialLink;
