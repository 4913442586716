import { useAppSelector, useWindowSize } from 'infrastructure/hooks';
import { useEffect, useState } from 'react';

const useLang = () => {
  const { width } = useWindowSize();
  const { lang } = useAppSelector((state) => state.home);
  const [textInfo, setTextInfo] = useState('');

  useEffect(() => {
    if (lang === 'es') {
      setTextInfo('Corporación Xeus');
    } else {
      setTextInfo('Xeus Corporation');
    }
  }, [lang, width]);

  return { textInfo, width };
};

export default useLang;
