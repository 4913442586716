import { bannerImg } from 'infrastructure/assets';
import { useLang } from './hooks';

import * as S from './Vision.styled';

const Vision = () => {
  const { textInfo } = useLang();

  return (
    <S.Vision>
      <S.Vision__InnerCont>
        <S.VisionTextCont>
          <S.Title>{textInfo.title}</S.Title>
          <S.VisionText>{textInfo.description}</S.VisionText>
        </S.VisionTextCont>
        <S.VisionImgCont src={bannerImg} alt='decoration item' />
      </S.Vision__InnerCont>
    </S.Vision>
  );
};

export default Vision;
