import {
  // smokeDetection,
  // industrialProcessAutomation,
  // fiberOptic,
  // robotics,
  empacaduras,
  correas,
  esparragos,
  fittings,
  laminas,
  materialElectrico,
  tuberia,
} from 'infrastructure/assets';

export const CardListES: { label: string; img: string }[] = [
  {
    label: 'Empacaduras',
    img: empacaduras,
  },
  { label: 'Esparragos', img: esparragos },
  {
    label: 'Tuberias',
    img: tuberia,
  },
  { label: 'Fittings', img: fittings },
  { label: 'Laminas', img: laminas },
  { label: 'Correas Industriales', img: correas },
  { label: 'Material Electrico', img: materialElectrico },
];

export const CardListENG: { label: string; img: string }[] = [
  {
    label: 'Gaskets',
    img: empacaduras,
  },
  { label: 'Threaded', img: esparragos },
  {
    label: 'Pipelines',
    img: tuberia,
  },
  { label: 'Fittings', img: fittings },

  { label: 'Roof Sheet', img: laminas },
  { label: 'Industrial belts', img: correas },
  { label: 'Electrical Material', img: materialElectrico },
];
