import styled from 'styled-components';

export const Footer = styled.footer`
  height: auto;
  min-height: auto;
`;

export const Footer__InnerCont = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0 auto;
  padding: 40px 46px;
  height: 100%;
  width: 100%;
  max-width: 900px;

  @media screen and (min-width: 600px) {
    justify-content: space-between;
    flex-direction: row;
  }
`;
