import { useAppSelector } from 'infrastructure/hooks';
import { ProductListES, ProductListENG } from '../utils';
import { useEffect, useState } from 'react';

const useLang = () => {
  const { lang } = useAppSelector((state) => state.home);
  const [textInfo, setTextInfo] = useState<{
    title: string;
    description: string;
    productList: string[];
  }>();

  useEffect(() => {
    if (lang === 'es') {
      setTextInfo({
        title: 'Contratación de equipos',
        description:
          'Corporation Xeus cuenta con un amplio catálogo de equipos para diversas áreas industriales, como transporte, construcción, minería, manufactura, entre los cuales podemos contar con:',
        productList: ProductListES,
      });
    } else {
      setTextInfo({
        title: 'Equipment contracting',
        description:
          'Equipment Contracting Corporation Xeus has a wide range of equipment for several industrial areas, such as transportation, construction, mining, manufacturing, among which we can count with:',
        productList: ProductListENG,
      });
    }
  }, [lang]);

  return { textInfo };
};

export default useLang;
