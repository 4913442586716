import { SocialLink, FooterColumn } from 'infrastructure/components';
import { useAppSelector } from 'infrastructure/hooks';

import * as S from './Footer.styled';

const Footer = () => {
  const { lang } = useAppSelector((state) => state.home);

  return (
    <S.Footer>
      <S.Footer__InnerCont>
        <SocialLink />
        <FooterColumn
          footerColumnCustomProps={{
            title: lang === 'es' ? 'Conoce' : 'Learn',
            tagList:
              lang === 'es'
                ? ['Productos', 'Servicios', 'Industrias']
                : ['Products', 'Services', 'industries'],
          }}
        />
        <FooterColumn
          footerColumnCustomProps={{
            title: lang === 'es' ? 'Compañia' : 'Company',
            tagList:
              lang === 'es'
                ? ['Quienes somos', 'Misión', 'Visión']
                : ['About us', 'Mission', 'Vision'],
          }}
        />
      </S.Footer__InnerCont>
    </S.Footer>
  );
};

export default Footer;
