import {
  smokeDetection,
  industrialProcessAutomation,
  fiberOptic,
  robotics,
} from 'infrastructure/assets';

export const CardListES: { label: string; img: string }[] = [
  {
    label: 'Sistemas inteligentes de deteccion de humo y gases toxicos',
    img: smokeDetection,
  },
  { label: 'Automatizacion de procesos industriales', img: industrialProcessAutomation },
  { label: 'Instalacion y mantenimiento de redes en fibra optica', img: fiberOptic },
  { label: 'Robotica', img: robotics },
];

export const CardListENG: { label: string; img: string }[] = [
  { label: 'Intelligent smoke and toxic gas detection systems', img: smokeDetection },
  { label: 'Industrial Process Automation', img: industrialProcessAutomation },
  { label: 'Installation and maintenance of fiber optic networks', img: fiberOptic },
  { label: 'Robotics', img: robotics },
];
