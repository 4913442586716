import { useLang } from './hooks';

import * as S from './WhoAreWe.styled';

const WhoAreWe = () => {
  const { textInfo } = useLang();

  return (
    <S.WhoAreWe id='quienesSomos'>
      <S.WhoAreWe__InnerCont>
        <S.Title>{textInfo.title}</S.Title> <S.Vline />
        <S.Info>{textInfo.text}</S.Info>
      </S.WhoAreWe__InnerCont>
    </S.WhoAreWe>
  );
};

export default WhoAreWe;
