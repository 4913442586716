import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import GlobalStyle from 'globalStyle';
import { store } from 'infrastructure/store';
import { Provider } from 'react-redux';

createRoot(document.getElementById('root') as HTMLElement, {
  identifierPrefix: 'id-',
}).render(
  <React.StrictMode>
    <Provider store={store}>
      <GlobalStyle />
      <App />
    </Provider>
  </React.StrictMode>
);
