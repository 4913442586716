import styled from 'styled-components';
import { Section, SectionTitle } from 'globalStyle';

export const Mision = styled(Section)`
  margin: 80px 0 150px 0;
  min-height: auto;
  height: auto;

  @media screen and (min-width: 600px) {
    margin-top: 90px;
  }

  @media screen and (min-width: 900px) {
    margin-top: 130px;
  }
`;

export const Mision__InnerCont = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  height: 100%;
  max-width: 1920px;
  margin: 0 auto;
  margin-bottom: 30px;
  padding: 0px 30px 0 30px;

  @media screen and (min-width: 600px) {
    padding: 0px 40px 0 40px;
  }

  @media screen and (min-width: 900px) {
    padding: 0px 70px 0 70px;
  }

  @media screen and (min-width: 1200px) {
    padding: 0px 102px 0 102px;
  }
`;

export const MisionPic = styled.img`
  height: 165px;
  max-width: 145px;
  object-fit: cover;

  @media screen and (min-width: 600px) {
    height: 190px;
    max-width: 170px;
  }

  @media screen and (min-width: 900px) {
    height: 350px;
    max-width: 240px;
  }

  @media screen and (min-width: 1200px) {
    height: 300px;
    max-width: 409px;
  }
`;

export const Vline = styled.div`
  height: 124px;
  margin: 0 32px 0 0px;

  @media screen and (min-width: 600px) {
    margin: 0 45px 0 0px;
    height: 180px;
  }

  @media screen and (min-width: 900px) {
    margin: 0 65px 0 0px;
    height: 226px;
  }

  @media screen and (min-width: 1200px) {
    margin: 0 90px 0 0px;
  }
`;

export const InfoCont = styled.div`
  font-size: 12px;
  width: 143px;
  font-weight: 400;
  margin: 0;
  line-height: 14.52px;

  @media screen and (min-width: 600px) {
    font-size: 16px;
    line-height: 18px;
    width: 200px;
  }

  @media screen and (min-width: 900px) {
    font-size: 16px;
    line-height: 22px;
    width: 300px;
  }

  @media screen and (min-width: 1200px) {
    font-size: 18px;
    line-height: 29px;
    width: 533px;
  }
`;

export const MisionText = styled.p`
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  line-height: 14.52px;

  @media screen and (min-width: 600px) {
    line-height: 18px;
  }

  @media screen and (min-width: 900px) {
    font-size: 18px;
    line-height: 22px;
  }

  @media screen and (min-width: 1200px) {
    font-size: 18px;
    line-height: 21px;
  }
`;

export const Title = styled(SectionTitle)`
  margin-bottom: 15px;

  @media screen and (min-width: 600px) {
    margin-bottom: 15px;
  }

  @media screen and (min-width: 900px) {
    margin-bottom: 20px;
  }

  @media screen and (min-width: 1200px) {
    margin-bottom: 28px;
  }
`;
