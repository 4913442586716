import { useAppSelector, useWindowSize } from 'infrastructure/hooks';
import { useEffect, useState } from 'react';

const useLang = () => {
  const { width } = useWindowSize();
  const { lang } = useAppSelector((state) => state.home);
  const [textInfo, setTextInfo] = useState<{
    text: string;
  }>({
    text: '',
  });

  useEffect(() => {
    if (lang === 'es' && width > 900) {
      setTextInfo({
        text: 'Corporación Xeus está especializada en el suministro y servicios de elevada calidad enfocados en cubrir las necesidades del sector: petrolero, gasífero, minero, petroquímico y afines. Garantizando la seguridad y la confiabilidad de nuestros productos a la industria Venezolana en general.',
      });
    } else if (lang === 'es' && width < 900) {
      setTextInfo({
        text: 'Corporación Xeus está especializada en el suministro y servicios de elevada calidad enfocados en cubrir las necesidades del sector: petrolero, gasífero, minero, petroquímico y afines. Garantizando la seguridad y la confiabilidad de nuestros productos a la industria Venezolana en general.',
      });
    } else if (lang === 'eng' && width > 900) {
      setTextInfo({
        text: 'Xeus Corporation is specialized in the supply and high quality services focused on covers the needs of the sector: oil, gas, mining, petrochemical and related. guaranteeing the safety and reliability of our products to the Venezuelan industry in general.',
      });
    } else if (lang === 'eng' && width < 900) {
      setTextInfo({
        text: 'Xeus Corporation is specialized in the supply and high quality services focused on covers the needs of the sector: oil, gas, mining, petrochemical and related. guaranteeing the safety and reliability of our products to the Venezuelan industry in general.',
      });
    } else {
      return;
    }
  }, [lang, width]);

  return { textInfo };
};

export default useLang;
