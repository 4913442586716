import { PageLogo, Navbar, MobileNavbar } from 'infrastructure/components';
import { useAppSelector } from 'infrastructure/hooks';

import * as S from './Header.styled';

const Header = () => {
  const { lang } = useAppSelector((state) => state.home);

  const links = [
    { name: lang === 'es' ? 'Inicio' : 'Home', to: '/' },
    { name: lang === 'es' ? 'Quienes somos' : 'About us', to: '#quienesSomos' },
    { name: lang === 'es' ? 'Productos' : 'Products', to: '#productos' },
    { name: lang === 'es' ? 'Servicios' : 'Services', to: '#servicios' },
    { name: lang === 'es' ? 'Contacto' : 'Contact', to: '#contacto' },
  ];

  return (
    <S.Header>
      <S.HeaderInnerCont>
        <PageLogo />
        <Navbar links={links} /> <MobileNavbar links={links} />
      </S.HeaderInnerCont>
    </S.Header>
  );
};

export default Header;
