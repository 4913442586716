import { Suspense } from 'react';
import { MoonLoader } from 'react-spinners';
import { Header } from 'infrastructure/components';
import { Outlet } from 'react-router-dom';

import * as S from './LayoutRoot.styled';

const LayoutRoot = () => {
  return (
    <S.LayoutRoot data-component-name='Layout Root'>
      <Header />
      <Suspense
        fallback={
          <div
            style={{
              width: '100vw',
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <MoonLoader />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </S.LayoutRoot>
  );
};

export default LayoutRoot;
