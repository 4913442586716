import { useAppSelector } from 'infrastructure/hooks';

interface IValidation {
  name: string;
  email: string;
  message: string;
}

const useValidation = () => {
  const { lang } = useAppSelector((state) => state.home);

  const validation = (props: IValidation) => {
    const errors = {
      name: '',
      email: '',
      message: '',
    };

    //Validation for fullName
    if (!props.name.trim()) {
      errors.name = lang === 'es' ? 'Este campo es requerido' : 'This field is required';
    } else if (
      props.name.trim() &&
      (props.name.trim().length < 3 || props.name.trim().length > 16)
    ) {
      errors.name =
        lang === 'es'
          ? 'Este campo solo acepta de 3 a 16 caracteres!'
          : 'This field only accepts 3 to 16 characters!';
    } else if (props.name.trim() && !/^[a-zA-Z ]*$/.test(props.name.trim())) {
      errors.name =
        lang === 'es'
          ? 'Este campo no acepta numeros o caracteres especiales!'
          : 'This field does not accept numbers or special characters!';
    } else {
      errors.name = '';
    }

    //Validation for email
    if (!props.email.trim()) {
      errors.email = 'Este campo es requerido';
    } else if (
      props.email.trim() &&
      !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(props.email.trim())
    ) {
      errors.email =
        lang === 'es' ? 'Por favor introduzca un email valido' : 'Please enter a valid email';
    } else {
      errors.email = '';
    }

    //Validation for message
    if (!props.message.trim()) {
      errors.message = lang === 'es' ? 'Este campo es requerido' : 'This field is required';
    } else if (props.message.trim() && props.message.trim().length < 10) {
      errors.message =
        lang === 'es'
          ? 'El campo mensaje debe ser minimo 10 caracteres!'
          : 'The message field must be at least 10 characters!';
    } else {
      errors.message = '';
    }

    return errors;
  };

  return { validation };
};

export default useValidation;
