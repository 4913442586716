import { MisionPic } from 'infrastructure/assets';
import { useLang } from './hooks';

import * as S from './Mision.styled';

const Mision = () => {
  const { textInfo } = useLang();

  return (
    <S.Mision>
      <S.Mision__InnerCont>
        <S.MisionPic src={MisionPic} alt='Mision' />
        <S.Vline />
        <S.InfoCont>
          <S.Title>{textInfo.title}</S.Title>
          <S.MisionText>{textInfo.text}</S.MisionText>
        </S.InfoCont>
      </S.Mision__InnerCont>
    </S.Mision>
  );
};

export default Mision;
