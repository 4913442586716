import styled from 'styled-components';

export const ProductItem = styled.li`
  display: flex;
  margin: 0 10px 20px 10px;
`;

export const ItemNumber = styled.span`
  margin-right: 14px;
  font-size: 14px;
  font-weight: 900;

  @media screen and (min-width: 600px) {
    font-size: 14px;
  }

  @media screen and (min-width: 900px) {
    font-size: 20px;
  }
`;

export const ItemName = styled.span`
  font-size: 16px;
  font-weight: 400;
  width: 150px;

  @media screen and (min-width: 360px) {
    width: 85px;
  }

  @media screen and (min-width: 450px) {
    width: 130px;
  }

  @media screen and (min-width: 600px) {
    font-size: 14px;
    width: 200px;
  }

  @media screen and (min-width: 900px) {
    font-size: 16px;
    width: 300px;
  }

  @media screen and (min-width: 1200px) {
    font-size: 18px;
    width: 300px;
  }
`;
