import useId from '@mui/material/utils/useId';
import { ServiceCard } from 'infrastructure/components';
import { useLang } from './hooks';

import * as S from './Supplies.styled';

const Supplies = () => {
  const cardId = useId();
  const { textInfo } = useLang();

  return (
    <S.Supplies id='servicios'>
      <S.Supplies__InnerCont>
        <S.Title>{textInfo.title}</S.Title>
        <S.Description>{textInfo.description}</S.Description>
        <S.CardCont>
          {textInfo.cardList.map(({ img, label }, i) => (
            <ServiceCard serviceCardCustomProps={{ img, text: label }} key={`${cardId}-${i}`} />
          ))}
        </S.CardCont>
      </S.Supplies__InnerCont>
    </S.Supplies>
  );
};

export default Supplies;
