import { Home } from 'infrastructure/pages';
import { LayoutRoot, LayoutMain } from 'infrastructure/layouts';

type ReactComponent = () => JSX.Element;

interface RoutesProps {
  path: string;
  Component: React.LazyExoticComponent<ReactComponent> | ReactComponent;
  nestedRoutes?: RoutesProps[];
}

//this components have the max of 1920
const homeRoutes: RoutesProps[] = [
  {
    path: '/',
    Component: Home,
  },
];

//This are the sub routes of the LayoutRoot, and are represented as a outlet in the LayoutRoot component
const mainRoutes: RoutesProps[] = [
  {
    path: '/',
    Component: LayoutMain,
    nestedRoutes: homeRoutes,
  },
];

const rootRoutes: RoutesProps[] = [
  {
    path: '/',
    Component: LayoutRoot,
    nestedRoutes: mainRoutes,
  },
];

export default rootRoutes;
