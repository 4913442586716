import { CSSProperties } from 'react';
import * as S from './MainButton.styled';

interface Props {
  MainButtonProps?: MainButtonProps;
  className?: string;
  style?: CSSProperties;
}

interface MainButtonProps {
  text: string;
  disabled?: boolean;
  onClick?: () => void;
  type?: 'button' | 'reset' | 'submit';
}

const MainButton = ({ MainButtonProps, className, style }: Props) => {
  let customProps: MainButtonProps = MainButtonProps || {
    text: 'Button',
    disabled: false,
    type: 'button',
  };

  return (
    <S.MainButton {...customProps} className={className} style={style}>
      {customProps?.text}
    </S.MainButton>
  );
};

export default MainButton;
