import { Section, SectionTitle } from 'globalStyle';
import styled from 'styled-components';

export const Services = styled(Section)`
  height: auto;
  min-height: auto;
`;

export const Services__InnerCont = styled.div`
  margin: 0 auto;
  padding: 56px 46px;
  height: 100%;
  width: 100%;
  max-width: 1140px;
`;

export const Title = styled(SectionTitle)`
  margin: 0 10px 32px 10px;
`;

export const Description = styled.p`
  display: none;
  font-size: 18px;
  line-height: 22px;

  @media screen and (min-width: 600px) {
    display: block;
  }
`;

export const CardCont = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  padding: 0px;

  @media screen and (min-width: 600px) {
    justify-content: space-between;
  }
`;
