import {
  Banner,
  WhoAreWe,
  Mision,
  Equipment,
  Services,
  Supplies,
  SupplyGroup,
  Vision,
  Contact,
} from 'infrastructure/components';

import * as S from './Home.styled';

const Home = () => {
  return (
    <S.Home>
      <Banner />
      <WhoAreWe />
      <Mision />
      <Equipment />
      <Services />
      <Supplies />
      <Vision />
      <SupplyGroup />
      <Contact />
    </S.Home>
  );
};

export default Home;
