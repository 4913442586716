import * as S from './ServiceCard.styled';

interface Props {
  serviceCardCustomProps?: ServiceCardCustomProps;
  serviceCardProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
}

interface ServiceCardCustomProps {
  img: string;
  text: string;
}

const ServiceCard = ({ serviceCardCustomProps, ...serviceCardProps }: Props) => {
  const ServiceCardContent: ServiceCardCustomProps = serviceCardCustomProps || {
    img: 'https://via.placeholder.com/150',
    text: 'lorem impun lorem',
  };

  return (
    <S.ServiceCard {...serviceCardProps}>
      <S.CardImage src={ServiceCardContent.img} alt='card img' />
      <S.CardInfoContent>
        <S.CardText>{ServiceCardContent.text}</S.CardText>
      </S.CardInfoContent>
    </S.ServiceCard>
  );
};

export default ServiceCard;
