import { createSlice } from '@reduxjs/toolkit';

export interface HomeState {
  lang: 'es' | 'eng';
}

const initialState: HomeState = {
  lang: 'es',
};

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    changeLanguage: (state) => {
      if (state.lang === 'es') {
        state.lang = 'eng';
      } else {
        state.lang = 'es';
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeLanguage } = homeSlice.actions;
