import styled from 'styled-components';

export const FooterColumn = styled.div`
  margin-bottom: 35px;
`;

export const Title = styled.span`
  display: block;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: start;
  color: #000023;

  @media screen and (min-width: 600px) {
    font-size: 16px;
  }

  @media screen and (min-width: 900px) {
    font-size: 18px;
  }
`;

export const TagList = styled.ul`
  color: #000023;
  text-align: start;
`;

export const TagItem = styled.li`
  font-size: 12px;
  margin-bottom: 15px;
  cursor: pointer;

  @media screen and (min-width: 600px) {
    font-size: 14px;
    text-align: start;
  }

  @media screen and (min-width: 900px) {
    font-size: 16px;
  }
`;
