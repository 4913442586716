import { ProductItem } from 'infrastructure/components';
import { useAppSelector } from 'infrastructure/hooks';
import { useId } from 'react';
import { useLang } from './hooks';

import * as S from './Equipment.styled';

const Equipment = () => {
  const { lang } = useAppSelector((state) => state.home);
  const itemId = useId();
  const { textInfo } = useLang();

  return (
    <S.Equipment id='productos'>
      <S.Equipment__InnerCont>
        <S.Title>{textInfo?.title}</S.Title>
        <S.Description>{textInfo?.description}</S.Description>
        <S.EquipmentList>
          {textInfo?.productList.map((item, i) => (
            <ProductItem
              productItemCustomProps={{ label: item, number: i + 1 }}
              key={`${itemId + i}`}
            />
          ))}
          <S.AndMore>{lang === 'es' ? 'entre muchos otros.' : 'among many others'}</S.AndMore>
        </S.EquipmentList>
      </S.Equipment__InnerCont>
    </S.Equipment>
  );
};

export default Equipment;
