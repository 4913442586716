import { Section } from 'globalStyle';
import styled from 'styled-components';

export const Vision = styled(Section)`
  height: auto;
  min-height: auto;
`;

export const Vision__InnerCont = styled.div`
  margin: 0 auto;
  padding: 56px 56px;
  height: 100%;
  width: 100%;
  max-width: 1140px;
  display: grid;
  grid-template-columns: 60.8% 12.15% 24% 10px;
  grid-template-rows: 50px 202px 50px;
  min-height: 100%;

  @media screen and (min-width: 600px) {
    grid-template-rows: 24px 350px 24px;
  }

  @media screen and (min-width: 1200px) {
    grid-template-rows: 24px 519px 24px;
  }

  @media screen and (min-width: 1200px) {
    grid-template-columns: 580px 90px 350px 10px;
    grid-template-rows: 24px 519px 24px;
  }
`;

export const VisionTextCont = styled.div`
  grid-row: 1/4;
  grid-column: 1/3;
  background-color: #0c44ac;
  padding: 30px 20% 30px 20px;
  color: whitesmoke;

  @media screen and (min-width: 600px) {
    padding: 100px 20% 30px 40px;
  }

  @media screen and (min-width: 900px) {
    padding: 110px 20% 30px 50px;
  }

  @media screen and (min-width: 1200px) {
    padding: 150px 15% 30px 60px;
  }
`;

export const Title = styled.span`
  display: block;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 14px;

  @media screen and (min-width: 600px) {
    font-size: 28px;
  }
  @media screen and (min-width: 900px) {
    font-size: 36px;
  }

  @media screen and (min-width: 1200px) {
    font-size: 40px;
  }
`;

export const VisionText = styled.p`
  font-size: 13px;
  line-height: 16px;
  margin: 0;

  @media screen and (min-width: 600px) {
    font-size: 15px;
    line-height: 20px;
  }

  @media screen and (min-width: 900px) {
    font-size: 16px;
    line-height: 21px;
  }

  @media screen and (min-width: 1200px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const VisionImgCont = styled.img`
  grid-row: 2/3;
  grid-column: 2/6;
  //background-color: red;
  //object-position: -200px center;

  height: 100%;
  max-width: 100%;
  object-fit: cover;
`;

export const VisionImg = styled.img`
  height: 100%;
  max-width: 100%;
  object-fit: cover;
  object-position: -200px center;
`;
