import styled from 'styled-components';
import { Section } from 'globalStyle';

export const WhoAreWe = styled(Section)`
  position: relative;
  display: flex;
  min-height: auto;
  height: auto;

  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 50%,
    rgba(255, 255, 255, 1) 100%
  );
`;

export const WhoAreWe__InnerCont = styled.div`
  position: relative;
  top: -40px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1920px;
  margin: 0 auto;
  padding: 10px 30px;
  height: 100%;
  width: 100%;
`;

export const Title = styled.h2`
  color: #000023;
  font-size: 24px;

  @media screen and (min-width: 600px) {
    font-size: 32px;
  }

  @media screen and (min-width: 900px) {
    font-size: 42px;
  }

  @media screen and (min-width: 1200px) {
    font-size: 52px;
  }
`;

export const Vline = styled.div`
  border-left: 2px solid #000023;
  height: 124px;
  margin: 0 20px 0 15px;

  @media screen and (min-width: 600px) {
    margin: 0 45px 0 35px;
    height: 180px;
  }

  @media screen and (min-width: 900px) {
    margin: 0 65px 0 60px;
    height: 226px;
  }

  @media screen and (min-width: 1200px) {
    margin: 0 90px 0 85px;
    height: 280px;
  }
`;

export const Info = styled.p`
  font-size: 16px;
  width: 143px;
  font-weight: 400;
  margin: 0;
  line-height: 14.52px;

  @media screen and (min-width: 600px) {
    font-size: 18px;
    line-height: 18px;
    width: 200px;
  }

  @media screen and (min-width: 900px) {
    font-size: 18px;
    line-height: 22px;
    width: 300px;
  }

  @media screen and (min-width: 1200px) {
    font-size: 24px;
    line-height: 29px;
    width: 400px;
  }
`;
