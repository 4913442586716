import { useId } from 'react';
import { FormInput, FormTextArea, MainButton } from 'infrastructure/components';
import { useForm } from './hooks';
import MoonLoader from 'react-spinners/MoonLoader';
import { useAppSelector } from 'infrastructure/hooks';

import * as S from './ContactForm.styled';

const ContactForm = () => {
  const { lang } = useAppSelector((state) => state.home);

  const id = useId();
  const { values, errors, disableSubmit, isLoading, isValidating, handleSubmit, handleChange } =
    useForm(
      {
        name: '',
        email: '',
        message: '',
      },
      {
        name: '',
        email: '',
        message: '',
      }
    );

  return (
    <S.Form onSubmit={handleSubmit}>
      <FormInput
        FormInputProps={{
          id: `${id}-nameInput`,
          name: 'name',
          placeholder: lang === 'es' ? 'nombre' : 'name',
          type: 'text',
          errorMessage: errors.name,
          onChange: handleChange,
          value: values.name,
          required: true,
          isValidating,
        }}
      />
      <FormInput
        FormInputProps={{
          id: `${id}-emailInput`,
          name: 'email',
          placeholder: lang === 'es' ? 'correo' : 'email',
          type: 'email',
          errorMessage: errors.email,
          onChange: handleChange,
          value: values.email,
          required: true,
          isValidating,
        }}
      />
      <FormTextArea
        FormTextAreaProps={{
          id: `${id}-textArea`,
          name: 'message',
          placeholder: lang === 'es' ? 'mensaje' : 'message',
          errorMessage: errors.message,
          onChange: handleChange,
          value: values.message,
          required: true,
          isValidating,
        }}
      />
      {isLoading ? (
        <S.SpinnerCont>
          <MoonLoader color='#1F3487' size={50} speedMultiplier={0.5} />
        </S.SpinnerCont>
      ) : (
        <MainButton
          MainButtonProps={{
            text: lang === 'es' ? 'ENVIAR' : 'SEND',
            disabled: disableSubmit,
            type: 'submit',
          }}
        />
      )}
    </S.Form>
  );
};

export default ContactForm;
