import { Props } from './interfaces';
import * as S from './Navbar.styled';

const Navbar = ({ links, color, size, ...restProps }: Props) => {
  return (
    <S.Navbar {...restProps}>
      {links.map(({ to, name }) => (
        <S.LinkTo to={to} key={name} color={color} size={size}>
          {name}
        </S.LinkTo>
      ))}
    </S.Navbar>
  );
};

export default Navbar;
