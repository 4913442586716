import { GrFacebookOption, GrTwitter, GrLinkedinOption } from 'react-icons/gr';
import { AiOutlinePhone, AiOutlineMail } from 'react-icons/ai';

import styled from 'styled-components';

export const SocialLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 25px;

  @media screen and (min-width: 600px) {
    margin-bottom: 25px;
  }
`;

export const title = styled.span`
  font-size: 12px;
  margin-bottom: 20px;
  font-weight: 700;
  letter-spacing: 1px;

  @media screen and (min-width: 600px) {
    font-size: 14px;
    text-align: start;
  }
`;

export const LinksCont = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const FacebookCont = styled.div`
  background-color: #ef5927;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0 10px 0 0;
  cursor: pointer;
`;

export const FacebookInnerCont = styled.div`
  position: relative;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  width: 15px;
  height: 15px;
`;

export const Facebook = styled(GrFacebookOption)`
  bottom: -2px;
  font-size: 16px;
  position: absolute;
  color: #ef5927;
  z-index: 1;
`;

export const IconCont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ef5927;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0 10px;
  cursor: pointer;
`;

export const Twitter = styled(GrTwitter)`
  color: #ef5927;
  font-size: 12px;
`;

export const Linkedin = styled(GrLinkedinOption)`
  color: #ef5927;
  font-size: 12px;
`;

export const EmailCont = styled.a`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  color: #000130;
`;

export const EmailIcon = styled(AiOutlineMail)`
  font-size: 16px;
  margin-right: 10px;
`;

export const Email = styled.span`
  font-size: 14px;
  padding-bottom: 1px;
  font-weight: 500;
`;

export const PhoneNumberCont = styled.a`
  display: flex;
  align-items: center;
  color: #000130;
  margin-bottom: 5px;
`;

export const PhoneIcon = styled(AiOutlinePhone)`
  font-size: 16px;
  margin-right: 10px;
`;

export const Phone = styled.span`
  font-size: 14px;
  padding-bottom: 1px;
  font-weight: 500;
`;

export const IkusaRight = styled.p`
  color: #000130;
  font-size: 12px;
  position: absolute;
  bottom: 5%;
  text-align: center;
  margin: 20px 0 0 0;

  @media screen and (min-width: 600px) {
    position: static;
    bottom: auto;
    text-align: left;
  }
`;

export const RightLogo = styled.span`
  font-weight: 600;
`;
