import { ElementProps } from 'react-html-props';

import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const Navbar = styled.nav.attrs((props: ElementProps) => props)`
  display: none;
  align-items: center;

  @media screen and (min-width: 900px) {
    display: flex;
  }
`;

interface LinkToProps {
  color?: string;
  size?: 'small' | 'normal';
}

export const LinkTo = styled(Link)<LinkToProps>`
  color: ${({ color }) => (color ? color : 'var(--color-aquamarine)')};
  margin-right: 25px;
  font-weight: 600;

  ${({ size }) =>
    size === 'small'
      ? css`
          font-size: 0.8rem;
          margin-right: 10px;
        `
      : null}
`;
