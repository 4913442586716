import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const PageLogo = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
`;

export const LogoImg = styled.img`
  height: 36px;
  max-height: 100%;
  display: none;

  @media screen and (min-width: 600px) {
    display: block;
  }

  @media screen and (min-width: 1200px) {
    height: 38px;
  }

  &[data-mobile='true'] {
    display: block;
    @media screen and (min-width: 600px) {
      display: none;
    }
  }
`;
