import { useAppSelector, useWindowSize } from 'infrastructure/hooks';
import { useEffect, useState } from 'react';

const useLang = () => {
  const { width } = useWindowSize();
  const { lang } = useAppSelector((state) => state.home);
  const [textInfo, setTextInfo] = useState<{
    title: any;
    text: any;
  }>({
    title: '',
    text: '',
  });

  useEffect(() => {
    if (lang === 'es' && width < 900) {
      setTextInfo({
        title: 'Misión',
        text: 'Somos una empresa dedicada a la prestación de servicios, construcción de obras y mantenimiento integral de equipos e instalaciones industriales, consultoría y suministro de toda clase de bienes e insumos para el área petrolera, petroquímica, carbonífera e industrial.',
      });
    } else if (lang === 'es' && width > 900) {
      setTextInfo({
        title: 'Misión',
        text: 'Somos una empresa dedicada a la prestación de servicios, construcción de obras y mantenimiento integral de equipos e instalaciones industriales, consultoría y suministro de toda clase de bienes e insumos para el área petrolera, petroquímica, carbonífera e industrial. En Corporación Xeus estamos orientados al logro de los objetivos de nuestros clientes para garantizar su rendimiento. Nos caracterizamos por ofrecer servicios oportunos, eficientes y fiables.',
      });
    } else if (lang === 'eng' && width < 900) {
      setTextInfo({
        title: 'Mission',
        text: 'We are a company dedicated to the provision of services, construction of works and integral maintenance of industrial equipment and facilities, consulting and supply of all kinds of goods and supplies for the oil, petrochemical, coal and industrial areas.',
      });
    } else if (lang === 'eng' && width > 900) {
      setTextInfo({
        title: 'Mission',
        text: "We are a company dedicated to the provision of services, construction of works and integral maintenance of industrial equipment and facilities, consulting and supply of all kinds of goods and supplies for the oil, petrochemical, coal and industrial areas. In Xeus Corporation we are oriented to the achievement of our clients' objectives to guarantee their performance. We are characterized by offering timely, efficient and reliable services.",
      });
    } else {
      return;
    }
  }, [lang, width]);

  return { textInfo };
};

export default useLang;
