import styled from 'styled-components';

export const LayoutMain = styled.div`
  grid-area: page;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #15131d50;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #f2f2f230;
  }
`;
