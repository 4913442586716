import { useAppSelector } from 'infrastructure/hooks';
import { CardListES, CardListENG } from '../utils';
import { useEffect, useState } from 'react';

const useLang = () => {
  const { lang } = useAppSelector((state) => state.home);
  const [textInfo, setTextInfo] = useState<{
    title: string;
    description: String;
    cardList: { label: string; img: string }[];
  }>({
    title: '',
    description: '',
    cardList: [],
  });

  useEffect(() => {
    if (lang === 'es') {
      setTextInfo({
        title: 'Servicios',
        description:
          'Desarrollamos proyectos de ingeniería, procura y construcción, asumiendo la responsabilidad plena de los trabajos desde su primera fase:',
        cardList: CardListES,
      });
    } else if (lang === 'eng') {
      setTextInfo({
        title: 'Services',
        description:
          'We develop engineering, procurement and construction projects, assuming full responsibility for the work from its first phase:',
        cardList: CardListENG,
      });
    } else {
      return;
    }
  }, [lang]);

  return { textInfo };
};

export default useLang;
